


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCurrentTab1 extends Vue {
  activeName: number = 0;
  @Prop() foldData!: object;

  get id () {
    return this.$route.params.id;
  }

  @Watch('foldData', { deep: true })
  onFoldDataChange () {
    console.log(this.foldData, '傳過來的foldData-1');
  }
}

